<template>
  <div class="uk-container uk-container-expand uk-margin-top card-scrollable">
    <div class="uk-margin">
      <div uk-grid class="uk-grid-small">
        <div class="uk-inline uk-width-1-4">
          <multiselect
            v-model="meta.user_id"
            placeholder="Search mitra (by name)"
            label="name"
            name="mitra"
            track-by="name"
            :options="user_options"
            :searchable="true"
            :loading='isFetching'
            :internal-search="false"
            :options-limit="50"
            @search-change="val => setUserValues(val, true)"
            @select="e => getJobPartners({...meta, user_id: e.value})"
          >
          </multiselect>
        </div>
        <div class="uk-form-controls uk-width-auto">
          <select class="uk-select" id="form-horizontal-select" v-model="meta.status" @change="(e) => changeMeta(e, 'status')">
            <option :value=null>Select status</option>
            <option :value=0>Non Active</option>
            <option :value=1>Active</option>
            <option :value=2>Pending Interview</option>
            <option :value=3>Pending Mitra Sign</option>
            <option :value=4>Pending HR Sign</option>
          </select>
        </div>
        <div class="uk-inline">
          <button style="width:100px; cursor:pointer; height:40px" class="uk-button-primary" @click="showModal">Create</button>
        </div>
      </div>
    </div>

    <div class="uk-card uk-card-default uk-margin">
      <div class="uk-overflow-auto">
        <table class="uk-table uk-table-small uk-table-divider uk-table-striped">
          <thead>
            <tr>
              <th class="uk-text-center uk-width-medium">User (Mitra) Name</th>
              <th class="uk-text-center uk-width-small">Company</th>
              <th class="uk-text-center uk-width-medium">Property Name</th>
              <th class="uk-text-center uk-width-small">Role</th>
              <th class="uk-text-center">Shift</th>
              <!-- <th class="uk-text-center uk-width-medium">Fee</th> -->
              <th class="uk-text-center uk-width-small">Start Date</th>
              <th class="uk-text-center uk-width-small">End Date</th>
              <th class="uk-text-center uk-width-medium">Documents</th>
              <th class="uk-text-center uk-width-small">Status</th>
              <th class="uk-text-center uk-width-1-6">Action</th>
            </tr>
          </thead>
          <tbody v-if="job_partners.docs.length>0" class="uk-table-middle uk-text-center ">
            <tr v-for="(job_partner, i) in job_partners.docs" :key="i">
              <td>{{job_partner.user.fullname}}</td>
              <td>{{job_partner.company.name}}</td>
              <td>{{job_partner.company_office.name}}</td>
              <td>{{job_partner.role.name}}</td>
              <td>{{job_partner.shift.name}}</td>
              <!-- <td>
                <span v-if="job_partner.fee_normal_hourly">Normal: {{job_partner.fee_normal_hourly}}<br></span>
                <span v-if="job_partner.fee_overtime_hourly">Overtime: {{job_partner.fee_overtime_hourly}}</span>
              </td> -->
              <td>
                {{job_partner.start_date ? new Date(job_partner.start_date).toLocaleDateString("id-ID", { year: 'numeric', month: 'long', day: 'numeric' }) : null}}
              </td>
              <td>
                {{job_partner.end_date ? new Date(job_partner.end_date).toLocaleDateString("id-ID", { year: 'numeric', month: 'long', day: 'numeric' }) : null}}
              </td>
              <td>
                <a v-if="job_partner.agreement" class="uk-button-link" :href='job_partner.agreement'>View Agreement</a><br>
                <a v-if="job_partner.document" class="uk-button-link" :href='job_partner.document'>View Document</a>
              </td>
              <td>
                <div
                  class="label uk-margin-auto"
                  :style="`background-color: ${job_partner.status === 1 ? '#00cc00' : job_partner.status === 0 ? '#e63c3c' : 'gray' }`">
                  {{status_options.find(status => status.value === job_partner.status).name}}
                </div>
              </td>
              <td>
                <button
                  style="width:80px; cursor:pointer; margin-bottom:8px; height:30px; background:#333747"
                  class="uk-button-primary"
                  v-clipboard:copy="job_partner._id"
                  v-clipboard:success="onCopy">
                  Copy Id
                </button><br/>
                <button
                  style="width:80px; cursor:pointer; height:30px"
                  class="uk-button-default uk-margin-small-right"
                  @click="editJobPartner(job_partner)">
                  Edit
                </button>
                <button
                  v-if="isLoading"
                  style="width:80px; cursor:pointer; height:30px"
                  class="uk-button-danger" type="button" disabled>
                  <div uk-spinner></div>
                </button>
                <button
                  v-else
                  style="width:80px; cursor:pointer; height:30px"
                  class="uk-button-danger"
                  @click="showDeleteModal(job_partner)">
                  Delete
                </button>
              </td>
            </tr>
          </tbody>
          <empty-table v-else :colspan="6" />
        </table>
      </div>
      <pagination
        :total-data="job_partners.totalDocs"
        :change-limit="changeLimit"
        :change-page="changePage"
      />
    </div>

    <div id="formPartnerModal" uk-modal esc-close="false" bg-close="false">
      <div class="uk-modal-dialog">
        <button class="uk-modal-close-default" type="button" uk-close @click="hideModal"></button>
        <div class="uk-modal-header">
          <h2 class="uk-modal-title">Job Partner Form</h2>
        </div>
        <div class="uk-modal-body">

          <div class="uk-margin">
            <label class="uk-form-label">User <b class="uk-text-danger">*</b></label>
            <multiselect
              v-model="jobPartnerForm.user_id"
              placeholder="Type to search user (by name)"
              label="name"
              name="user"
              track-by="name"
              v-validate="'required'"
              :options="user_options"
              :searchable="true"
              :loading='isFetching'
              :internal-search="false"
              :options-limit="50"
              @search-change="setUserValues"
            >
            </multiselect>
            <span class="uk-text-small uk-text-danger" v-show="errors.has('user')">{{ errors.first('user') }}</span>
          </div>

          <div class="uk-margin">
            <label class="uk-form-label">Company <b class="uk-text-danger">*</b></label>
            <multiselect
              v-model="jobPartnerForm.company_id"
              placeholder="Type to search company"
              label="name"
              name="company"
              track-by="name"
              v-validate="'required'"
              :options="company_options"
              :searchable="true"
              :loading='isFetching'
              :internal-search="false"
              :options-limit="50"
              @search-change="setCompanyValues"
            >
            </multiselect>
            <span class="uk-text-small uk-text-danger" v-show="errors.has('company')">{{ errors.first('company') }}</span>
          </div>

          <div class="uk-margin">
            <label class="uk-form-label">Office (Property) <b class="uk-text-danger">*</b></label>
            <multiselect
              v-model="jobPartnerForm.company_office_id"
              placeholder="Type to search office"
              label="name"
              name="office"
              track-by="name"
              v-validate="'required'"
              :options="office_options"
              :searchable="true"
              :loading='isFetching'
              :internal-search="false"
              :options-limit="50"
              @search-change="setPropertyValues"
            >
            </multiselect>
            <span class="uk-text-small uk-text-danger" v-show="errors.has('office')">{{ errors.first('office') }}</span>
          </div>

          <div class="uk-margin">
            <label class="uk-form-label">Job <b class="uk-text-danger">*</b></label>
            <multiselect
              v-model="jobPartnerForm.job_id"
              placeholder="Type to search job"
              label="name"
              name="job"
              track-by="name"
              v-validate="'required'"
              :options="job_options"
              :searchable="true"
              :loading='isFetching'
              :internal-search="false"
              :options-limit="50"
              @search-change="setJobValues"
            >
            </multiselect>
            <span class="uk-text-small uk-text-danger" v-show="errors.has('job')">{{ errors.first('job') }}</span>
          </div>

          <div class="uk-margin">
            <label class="uk-form-label">Role <b class="uk-text-danger">*</b></label>
            <multiselect
              v-model="jobPartnerForm.role_id"
              placeholder="Type to search role"
              label="name"
              name="role"
              track-by="name"
              v-validate="'required'"
              :options="role_options"
              :searchable="true"
              :loading='isFetching'
              :internal-search="false"
              :options-limit="50"
              @search-change="setRoleValues"
            >
            </multiselect>
            <span class="uk-text-small uk-text-danger" v-show="errors.has('role')">{{ errors.first('role') }}</span>
          </div>

          <div class="uk-margin">
            <label class="uk-form-label">Shift <b class="uk-text-danger">*</b></label>
            <multiselect
              v-model="jobPartnerForm.shift_id"
              placeholder="Type to search shift"
              label="name"
              name="shift"
              track-by="name"
              v-validate="'required'"
              :options="shift_options"
              :searchable="true"
              :loading='isFetching'
              :internal-search="false"
              :options-limit="50"
              @search-change="setShiftValues"
            >
            </multiselect>
            <span class="uk-text-small uk-text-danger" v-show="errors.has('shift')">{{ errors.first('shift') }}</span>
          </div>

          <!-- <div class="uk-margin">
            <label class="uk-form-label">Normal Fee Hourly <b class="uk-text-danger">*</b></label>
            <input
              class="uk-input"
              :class="{'uk-form-danger': errors.has('fee_normal_hourly')}"
              name="fee_normal_hourly"
              type="number"
              v-model="jobPartnerForm.fee_normal_hourly"
              v-validate="'required'"
              placeholder="Normal Fee">
            <span class="uk-text-small uk-text-danger" v-show="errors.has('fee_normal_hourly')">{{ errors.first('fee_normal_hourly') }}</span>
          </div>

          <div class="uk-margin">
            <label class="uk-form-label">Overtime Fee Hourly <b class="uk-text-danger">*</b></label>
            <input
              class="uk-input"
              :class="{'uk-form-danger': errors.has('fee_overtime_hourly')}"
              name="fee_overtime_hourly"
              type="number"
              v-model="jobPartnerForm.fee_overtime_hourly"
              v-validate="'required'"
              placeholder="Overtime Fee">
            <span class="uk-text-small uk-text-danger" v-show="errors.has('fee_overtime_hourly')">{{ errors.first('fee_overtime_hourly') }}</span>
          </div> -->

          <div class="uk-margin">
            <label class="uk-form-label">Agreement Link</label>
            <input
              class="uk-input"
              :class="{'uk-form-danger': errors.has('agreement')}"
              name="agreement"
              type="text"
              v-model="jobPartnerForm.agreement"
              v-validate="{url: {require_protocol: true }}"
              placeholder="Agreement Link (include the 'http://' or 'https://')">
            <span class="uk-text-small uk-text-danger" v-show="errors.has('agreement')">{{ errors.first('agreement') }}</span>
          </div>

          <div class="uk-margin">
            <label class="uk-form-label">Document Link</label>
            <input
              class="uk-input"
              :class="{'uk-form-danger': errors.has('document')}"
              name="document"
              type="text"
              v-model="jobPartnerForm.document"
              v-validate="{url: {require_protocol: true }}"
              placeholder="Document Link (include the 'http://' or 'https://')">
            <span class="uk-text-small uk-text-danger" v-show="errors.has('document')">{{ errors.first('document') }}</span>
          </div>

          <div class="uk-margin">
            <label class="uk-form-label">Start Date <b class="uk-text-danger">*</b></label>
            <datetime
              type="date"
              :class="{'uk-form-danger': errors.has('start_date')}"
              name="start_date"
              v-model="jobPartnerForm.start_date"
              placeholder="Start Date"
              v-validate="'required'"
            ></datetime>
            <span class="uk-text-small uk-text-danger" v-show="errors.has('start_date')">{{ errors.first('start_date') }}</span>
          </div>

          <div class="uk-margin">
            <label class="uk-form-label">Job Partner Status <b class="uk-text-danger">*</b></label>
            <multiselect
              v-model="jobPartnerForm.status"
              placeholder="Select Status"
              label="name"
              name="job_partner_status"
              track-by="name"
              v-validate="'required'"
              :options="status_options">
            </multiselect>
            <span class="uk-text-small uk-text-danger" v-show="errors.has('job_partner_status')">{{ errors.first('job_partner_status') }}</span>
          </div>

          <div v-if="jobPartnerForm.status.name === 'Non Active'" class="uk-margin">
            <label class="uk-form-label">End Date <b class="uk-text-danger">*</b></label>
            <datetime
              type="date"
              :class="{'uk-form-danger': errors.has('end_date')}"
              name="end_date"
              v-model="jobPartnerForm.end_date"
              placeholder="End Date"
              v-validate="'required'"
            ></datetime>
            <span class="uk-text-small uk-text-danger" v-show="errors.has('end_date')">{{ errors.first('end_date') }}</span>
          </div>

        </div>
        <div class="uk-modal-footer uk-text-right">
          <button v-if="isLoading" class="uk-button uk-button-primary" type="button" disabled><div uk-spinner></div></button>
          <button v-else class="uk-button uk-button-primary" type="button" @click="saveJobPartner">Save</button>
        </div>
      </div>
    </div>

    <div id="deletePartnerModal" uk-modal esc-close="false" bg-close="false">
      <div class="uk-modal-dialog">
        <button class="uk-modal-close-default" type="button" uk-close @click="hideDeleteModal"></button>
        <div class="uk-modal-header">
          <h2 class="uk-modal-title">Delete Job Partner?</h2>
        </div>
        <div class="uk-modal-body">
          <div class="uk-margin-small">
            <div>
              <h5 class="uk-text-bold">User Name:</h5>
              <p>{{jobPartnerForm.user_id.name}}</p>
            </div>
            <div>
              <h5 class="uk-text-bold">Company Name:</h5>
              <p>{{jobPartnerForm.company_id.name}}</p>
            </div>
            <div>
              <h5 class="uk-text-bold">Office Name:</h5>
              <p>{{jobPartnerForm.company_office_id.name}}</p>
            </div>
            <div>
              <h5 class="uk-text-bold">Role Name:</h5>
              <p>{{jobPartnerForm.role_id.name}}</p>
            </div>
            <div>
              <h5 class="uk-text-bold">Shift Name:</h5>
              <p>{{jobPartnerForm.shift_id.name}}</p>
            </div>
            <div>
              <h5 class="uk-text-bold">Job Name:</h5>
              <p>{{jobPartnerForm.job_id.name}}</p>
            </div>
            <div>
              <h5 class="uk-text-bold">Status:</h5>
              <p>{{jobPartnerForm.status.name}}</p>
            </div>
          </div>
        </div>
        <div class="uk-modal-footer uk-text-right">
          <button v-if="isLoading" class="uk-button uk-button-primary" type="button" disabled><div uk-spinner></div></button>
          <button v-else class="uk-button uk-button-danger" type="button" @click="MarkPartnerDeleted">Delete</button>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import Pagination from '@/components/globals/Pagination';
import EmptyTable from '@/components/globals/tables/EmptyTable';
import { mapActions, mapGetters } from 'vuex';
import {
    notificationSuccess,
    notificationDanger
} from '@/utils/notification';
import { Datetime } from 'vue-datetime';

export default {
    data() {
        return {
            meta: {
                limit: 100,
                page: 1,
                user_id: '',
                status: null,
                is_deleted: false,
            },
            jobPartnerForm: {
                company_id: '',
                company_office_id: '',
                role_id: '',
                shift_id: '',
                job_id: '',
                user_id: '',
                status: '',
                // fee_normal_hourly: null,
                // fee_overtime_hourly: null,
                agreement: '',
                document: '',
                start_date: null,
                end_date: null,
            },
            formIsEdit: false,
            isLoading: false,
            status_options: [
                { name: 'Pending HR Sign', value: 4},
                { name: 'Pending Mitra Sign', value: 3},
                { name: 'Pending Interview', value: 2},
                { name: 'Active', value: 1},
                { name: 'Non Active', value: 0},
            ],
            company_options: [],
            office_options: [],
            role_options: [],
            shift_options: [],
            job_options: [],
            user_options: [],
            isFetching: false,
        };
    },
    components: {
        Pagination,
        EmptyTable,
        Datetime
    },
    computed: {
        ...mapGetters({
            job_partners: 'job_partner/job_partners',
            companies: 'company/companies',
            properties: 'property/properties',
            company_roles: 'company_role/company_roles',
            shifts: 'company_shift/shifts',
            jobs: 'job/jobs',
            users: 'user/users',
        })
    },
    watch: {
        meta() {
            this.setJobPartnerMeta();
        },
    },
    mounted() {
        this.setJobPartnerMeta();
    },
    methods: {
        ...mapActions({
            getJobPartners: 'job_partner/getJobPartners',
            createJobPartner: 'job_partner/createJobPartner',
            updateJobPartner: 'job_partner/updateJobPartner',
            deleteJobPartner: 'job_partner/deleteJobPartner',
            getCompanies: 'company/getCompanies',
            getCompanyRoles: 'company_role/getCompanyRoles',
            getShifts: 'company_shift/getShifts',
            getProperties: 'property/getProperties',
            getJob: 'job/getJob',
            getUser: 'user/getUser',
            addPartner: 'user/addPartner',
            deletePartner: 'user/deletePartner'
        }),
        async setCompanyValues(query) {
            this.isFetching = true;

            await this.getCompanies({name: query});
            this.company_options = this.companies.docs.map(
                obj => ({name: obj.name, value: obj._id})
            );

            this.isFetching = false;
        },
        async setPropertyValues(query) {
            this.isFetching = true;

            await this.getProperties({name: query});
            this.office_options = this.properties.docs.map(
                obj => ({name: obj.name, value: obj._id})
            );

            this.isFetching = false;
        },
        async setRoleValues(query) {
            this.isFetching = true;

            await this.getCompanyRoles({name: query});
            this.role_options = this.company_roles.docs.map(
                obj => ({name: obj.name, value: obj._id})
            );

            this.isFetching = false;
        },
        async setShiftValues(query) {
            this.isFetching = true;

            await this.getShifts({name: query});
            this.shift_options = this.shifts.docs.map(
                obj => ({name: obj.name, value: obj._id})
            );

            this.isFetching = false;
        },
        async setJobValues(query) {
            this.isFetching = true;

            await this.getJob({title: query});
            this.job_options = this.jobs.docs.map(
                obj => ({name: obj.title, value: obj._id})
            );

            this.isFetching = false;
        },
        async setUserValues(query, onlyPartner = false) {
            this.isFetching = true;

            await this.getUser({fullname: query, only_partner: onlyPartner});
            this.user_options = this.users.docs.map(
                obj => ({name: `${obj.fullname} (${obj.email})`, value: obj._id})
            );

            this.isFetching = false;
        },
        setJobPartnerMeta() {
            this.getJobPartners({...this.meta, user_id: this.meta.user_id.value});
        },
        changeMeta(e, prop) {
            this.meta = {
                ...this.meta,
                [prop]: e.target.value
            };
            this.getJobPartners({...this.meta, user_id: this.meta.user_id.value});
        },
        changeLimit(e) {
            this.meta = {
                ...this.meta,
                limit: e.target.value
            };
        },
        changePage(value) {
            this.meta = {
                ...this.meta,
                page: value
            };
        },
        async saveJobPartner() {
            try {
                const validate = await this.$validator.validateAll();
                if (!validate || this.$validator.errors.any()) return;

                this.isLoading = true;

                ['company_id', 'company_office_id', 'role_id',
                    'shift_id', 'job_id', 'user_id', 'status'
                ].forEach(prop => {
                    this.jobPartnerForm[prop] = this.jobPartnerForm[prop].value;
                });

                ['end_date', 'agreement', 'document'].forEach(prop => {
                    if (!this.jobPartnerForm[prop]) this.jobPartnerForm[prop] = null;
                });

                const response = this.formIsEdit
                    ? await this.updateJobPartner(this.jobPartnerForm)
                    : await this.createJobPartner(this.jobPartnerForm);

                const response_2 = await this.addPartner({
                    id: this.jobPartnerForm.user_id,
                    partner_id: this.jobPartnerForm.company_id
                });

                this.isLoading = false;
                if (response && response_2 && response.status === 'OK' && response_2.status === 'OK') {
                    notificationSuccess('Job Partner saved!');
                    this.hideModal();
                    this.setJobPartnerMeta();
                } else {
                    notificationDanger('Failed to save job partner.');
                }
            } catch (err) {
                notificationDanger(err);
            }
        },
        fillForm(job_partner) {
            try {
                this.jobPartnerForm.id = job_partner._id;
                this.jobPartnerForm.name = job_partner.name;
                // this.jobPartnerForm.fee_normal_hourly = job_partner.fee_normal_hourly;
                // this.jobPartnerForm.fee_overtime_hourly = job_partner.fee_overtime_hourly;
                this.jobPartnerForm.agreement = job_partner.agreement;
                this.jobPartnerForm.document = job_partner.document;
                this.jobPartnerForm.start_date = job_partner.start_date;
                this.jobPartnerForm.end_date = job_partner.end_date;
                this.jobPartnerForm.status = this.status_options.find(
                    e => e.value === job_partner.status
                );
                this.jobPartnerForm.company_id = {
                    name: job_partner.company.name,
                    value: job_partner.company._id
                };
                this.jobPartnerForm.company_office_id = {
                    name: job_partner.company_office.name,
                    value: job_partner.company_office._id
                };
                this.jobPartnerForm.role_id = {
                    name: job_partner.role.name,
                    value: job_partner.role._id
                };
                this.jobPartnerForm.shift_id = {
                    name: job_partner.shift.name,
                    value: job_partner.shift._id
                };
                this.jobPartnerForm.job_id = {
                    name: job_partner.job.title,
                    value: job_partner.job._id
                };
                this.jobPartnerForm.user_id = {
                    name: `${job_partner.user.fullname} (${job_partner.user.email})`,
                    value: job_partner.user._id
                };
            } catch (err) {
                notificationDanger(err);
            }
        },
        editJobPartner(job_partner) {
            this.formIsEdit = true;
            this.fillForm(job_partner);
            this.showModal();
        },
        async MarkPartnerDeleted() {
            try {
                this.isLoading = true;

                const response = await this.deleteJobPartner(this.jobPartnerForm.id);
                const response_2 = await this.deletePartner({
                    id: this.jobPartnerForm.user_id.value,
                    partner_id: this.jobPartnerForm.company_id.value
                });

                this.isLoading = false;

                if (response && response_2 && response.status === 'OK' && response_2.status === 'OK') {
                    notificationSuccess('Job partner deleted!');
                    this.hideDeleteModal();
                    this.setJobPartnerMeta();
                } else {
                    notificationDanger('Failed to delete job partner.');
                }
            } catch (err) {
                notificationDanger(err);
            }
        },
        resetForm() {
            this.formIsEdit = false;
            this.jobPartnerForm = {
                company_id: '',
                company_office_id: '',
                role_id: '',
                shift_id: '',
                job_id: '',
                user_id: '',
                status: '',
                agreement: '',
                document: '',
                start_date: null,
                end_date: null,
                // fee_normal_hourly: null,
                // fee_overtime_hourly: null,
            };
            this.$validator.reset();
        },
        async showDeleteModal(job_partner) {
            this.fillForm(job_partner);
            await window.UIkit.modal('#deletePartnerModal').show();
        },
        async hideDeleteModal() {
            await window.UIkit.modal('#deletePartnerModal').hide();
            this.resetForm();
        },
        async showModal() {
            await window.UIkit.modal('#formPartnerModal').show();
        },
        async hideModal() {
            await window.UIkit.modal('#formPartnerModal').hide();
            this.resetForm();
        },
        onCopy(e) {
            notificationSuccess('Copied to clipboard: ' + e.text);
        },
    }
};
</script>
